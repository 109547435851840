import { Root, Item } from '@radix-ui/react-toggle-group'
import styled from 'styled-components'

import { fonts, trispace } from 'theme/index'
import type { StripeInterval } from 'types/stripeConsts'

const PriceToggle = ({
    setPrice,
    selectedPrice,
}: {
    setPrice: (price: StripeInterval) => void
    selectedPrice: StripeInterval
}) => {
    const handleValueChange = (value: StripeInterval) => {
        // Make sure we can't "unselect" the price, so only set if there is a value
        if (!!value) {
            setPrice(value)
        }
    }

    return (
        <Toggle
            type="single"
            defaultValue={selectedPrice}
            aria-label="Select plan"
            value={selectedPrice}
            onValueChange={handleValueChange}
        >
            <PriceItem value="year" aria-label="Annual">
                <PriceItemText>Annual</PriceItemText>
            </PriceItem>
            <PriceItem value="month" aria-label="Monthly">
                <PriceItemText>Monthly</PriceItemText>
            </PriceItem>
        </Toggle>
    )
}

const Toggle = styled(Root)`
    display: flex;
    align-items: center;
`

const PriceItem = styled(Item)`
    flex: 1;
    padding: 0.5rem 0;
    border: none;
    background-color: ${({ theme }) => theme.palette.neutral[100]};
    color: ${({ theme }) => theme.palette.neutral[700]};
    &[data-state='on'] {
        background-color: ${({ theme }) => theme.palette.neutral[0]};
        border-top: 2px solid ${({ theme }) => theme.palette.primary[600]};
        color: ${({ theme }) => theme.palette.primary[600]};
    }
`

const PriceItemText = styled.p`
    font-family: ${trispace.style.fontFamily};
    font-size: ${fonts.size.S};
`

export default PriceToggle
