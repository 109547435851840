import { useEffect, useState } from 'react'

import styled from 'styled-components'

import Hide from 'components/Hide'
import PriceBox from 'components/PriceBoxes/PriceBox'
import PriceToggle from 'components/PriceBoxes/PriceToggle'
import type { Price } from 'lib/pricing/types'
import type { StripeInterval } from 'types/stripeConsts'

export type PriceBoxesProps = {
    yearly: Price
    monthly: Price
    setSelectedPrice: ((price: Price) => void) | null
    selectedPriceInterval?: StripeInterval
}

const PriceBoxes = ({
    yearly,
    monthly,
    setSelectedPrice,
    selectedPriceInterval = 'year',
}: PriceBoxesProps) => {
    const [selected, setSelected] = useState<StripeInterval>(selectedPriceInterval)

    useEffect(() => {
        setSelectedPrice &&
            (selected === 'year' ? setSelectedPrice(yearly) : setSelectedPrice(monthly))
    }, [selected, setSelectedPrice, yearly, monthly])

    const setMonthly = () => {
        setSelected('month')
        setSelectedPrice && setSelectedPrice(monthly)
    }

    const setYearly = () => {
        setSelected('year')
        setSelectedPrice && setSelectedPrice(yearly)
    }

    const MonthlyBox = () => (
        <PriceBox
            variant="month"
            price={monthly}
            onClick={setSelectedPrice ? setMonthly : undefined}
            isSelected={selected === 'month'}
        />
    )

    const YearlyBox = () => (
        <PriceBox
            variant="year"
            price={yearly}
            onClick={setSelectedPrice ? setYearly : undefined}
            isSelected={selected === 'year'}
        />
    )

    return (
        <Container>
            {/* Small screens - show toggle*/}
            <Hide on={[false, false, true]}>
                <SmallScreenContainer>
                    <PriceToggle setPrice={setSelected} selectedPrice={selected} />
                    {selected === 'year' && <YearlyBox />}
                    {selected === 'month' && <MonthlyBox />}
                </SmallScreenContainer>
            </Hide>

            {/* Large screens */}
            <Hide on={[true, true, false]}>
                <LargeScreenContainer>
                    <YearlyBox />
                    <MonthlyBox />
                </LargeScreenContainer>
            </Hide>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
`

const SmallScreenContainer = styled.div`
    padding-bottom: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-grow: 1;
`

const LargeScreenContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding-bottom: 2rem;
    width: 100%;
`

export default PriceBoxes
