import styled from 'styled-components'

import HighlightText from 'components/HighlightText'
import Box from 'components/Primitives/Box'
import Set from 'components/Set'
import createPriceString, { getDiscountText } from 'lib/createPriceString'
import type { Price } from 'lib/pricing/types'
import { trispace, zIndex } from 'theme/index'
import type { StripeInterval } from 'types/stripeConsts'

type Props = {
    variant: StripeInterval
    price: Price
    isSelected: boolean
    onClick?: (price: StripeInterval) => void // If undefined, the box is not clickable
}

const PriceBox = ({ variant, price, onClick, isSelected }: Props) => {
    const handleClick = () => {
        !!onClick && onClick(price.interval)
    }

    const isAnnual = variant === 'year'
    const isClickable = !!onClick

    const priceProps = {
        amount: price.amount,
        currency: price.currency,
        currencySymbol: price.currencySymbol,
        hideCurrencyInfo: true,
        stripeDiscountPct: price.promotionApplied?.discountPct || 0,
        stripeDiscountAmount: price.promotionApplied?.discountAmount || 0,
    }
    const discount = price.promotionApplied
    const discountText = getDiscountText(discount, price.currencySymbol)

    const pricePerMonthText = isAnnual
        ? createPriceString({
              ...priceProps,
              convertToMonth: true,
          })
        : createPriceString({
              ...priceProps,
          })

    const planText = isAnnual ? 'Annual' : 'Monthly'
    const featureText = isAnnual ? 'Most Popular' : 'Flexible'

    const subText = isAnnual ? (
        <>
            {!!price.trialDays && (
                <>
                    {`${price.trialDays} day free trial,`}
                    <br />
                    {/* This is the full annual price (with discount applied if any) */}
                    {`then ${createPriceString({ ...priceProps })}/year`}
                </>
            )}
        </>
    ) : (
        <>
            {`Cancel anytime`}
            <br />
            {'Billed monthly'}
        </>
    )

    return (
        <Container
            onClick={handleClick}
            $isSelected={isSelected}
            $isClickable={isClickable}
            data-cy={`price-box-${variant}`}
        >
            {discountText && (
                <DiscountBadgeContainer>
                    <DiscountBadge>{discountText}</DiscountBadge>
                </DiscountBadgeContainer>
            )}
            <PlanText>{planText}</PlanText>
            <FeatureText>{featureText}</FeatureText>

            <Box display="flex" flexDirection="row" alignItems="center">
                <PriceText data-cy={`${variant}-box-price-per-month`}>
                    {pricePerMonthText}
                </PriceText>
                <MonthlyText>/month</MonthlyText>
            </Box>

            <FooterSection>
                <SubText>{subText}</SubText>
            </FooterSection>
        </Container>
    )
}

const Container = styled(Set)<{ $isSelected: boolean; $isClickable: boolean }>`
    width: 100%;
    position: relative;
    min-width: 40%;
    min-height: 200px;
    padding: 2rem;
    margin: 0rem;
    margin-top: 1rem;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }) => theme.palette.neutral[999]};
    cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};
    border: ${({ $isSelected, $isClickable, theme }) => {
        if (!$isClickable) {
            return `2px solid ${theme.palette.primary[600]}`
        }
        return $isSelected ? `2px solid ${theme.palette.primary[600]}` : 'none'
    }};
    background-color: ${({ $isSelected, $isClickable, theme }) => {
        if (!$isClickable) {
            return theme.palette.neutral[0]
        }
        return $isSelected ? theme.palette.primary[100] : theme.palette.neutral[0]
    }};
    box-shadow: ${({ $isClickable, theme }) => ($isClickable ? theme.elevation[3] : 'none')};
`

const DiscountBadgeContainer = styled.div`
    position: absolute;
    top: -1.5rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    pointer-events: none;
`

const DiscountBadge = styled.div`
    position: absolute;
    top: -0.75rem;
    left: 50%;
    transform: translateX(-50%);

    clip-path: polygon(10px 0, 100% 0, calc(100% - 10px) 100%, 0 100%);
    background-color: ${({ theme }) => theme.palette.accent[500]};
    color: ${({ theme }) => theme.palette.neutral[0]};
    padding: 0.5rem 1.5rem;

    font-size: ${({ theme }) => theme.fontSize.XS};
    font-family: ${trispace.style.fontFamily};

    white-space: nowrap;
    z-index: ${zIndex.layer01};
`

const PlanText = styled(HighlightText)`
    font-size: ${({ theme }) => theme.fontSize.XXL};
    font-weight: ${({ theme }) => theme.fontWeights.extraBold};
    text-transform: uppercase;
    font-style: italic;
    margin-bottom: 0.5rem;
`

const FeatureText = styled.p`
    font-family: ${trispace.style.fontFamily};
    font-size: ${({ theme }) => theme.fontSize.XS};
    color: ${({ theme }) => theme.palette.primary[500]};
`

const PriceText = styled.p`
    font-size: ${({ theme }) => theme.fontSize.XXXXL};
    font-weight: ${({ theme }) => theme.fontWeights.extraBold};
    padding-right: 0.5rem;
`

const MonthlyText = styled.p`
    font-size: ${({ theme }) => theme.fontSize.S};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
`

const FooterSection = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`

const SubText = styled.p`
    font-size: ${({ theme }) => theme.fontSize.XS};
    text-align: center;
`

export default PriceBox
